import {
	json,
	type LoaderFunctionArgs,
	type HeadersFunction,
	type MetaFunction,
	type LinkDescriptor,
} from "@remix-run/node"

import { Links, Meta, Scripts, ScrollRestoration, useLoaderData } from "@remix-run/react"

import { withSentry } from "@sentry/remix"
import { useTranslation } from "react-i18next"
import { useChangeLanguage } from "remix-i18next/react"
import { HoneypotProvider } from "remix-utils/honeypot/react"
import { Toaster } from "sonner"
import { APP_NAME } from "#app/constants.ts"
import i18next from "#app/i18n/i18next.server.ts"
import { useTheme } from "#app/routes/resources+/theme-switch"
import { AppLayout } from "./app-layout.tsx"
import { GeneralErrorBoundary } from "./components/error-boundary.tsx"
import { EpicProgress } from "./components/progress-bar.tsx"
import { useToast } from "./components/toaster.tsx"

import { href as iconsHref } from "./components/ui/icon.tsx"
import { getUserLabelNames } from "./models/labels"
import tailwindStyleSheetUrl from "./styles/tailwind.css?url"
import { getUserId, logout } from "./utils/auth.server.ts"
import { ClientHintCheck, getHints } from "./utils/client-hints.tsx"
import { prisma } from "./utils/db.server.ts"
import { getEnv } from "./utils/env.server.ts"
import { honeypot } from "./utils/honeypot.server.ts"
import { cn, combineHeaders, getDomainUrl, getTitleFromMatches } from "./utils/misc.tsx"
import { useNonce } from "./utils/nonce-provider.ts"
import { type Theme, getTheme } from "./utils/theme.server.ts"
import { makeTimings, time } from "./utils/timing.server.ts"
import { getToast } from "./utils/toast.server.ts"

export function links(): LinkDescriptor[] {
	return [
		// Preload svg sprite as a resource to avoid render blocking
		{ rel: "preload", href: iconsHref, as: "image" },

		// Preload CSS as a resource to avoid render blocking
		{ rel: "mask-icon", href: "/favicons/mask-icon.svg" },

		{
			rel: "alternate icon",
			type: "image/png",
			href: "/favicons/favicon-32x32.png",
		},

		{ rel: "apple-touch-icon", href: "/favicons/apple-touch-icon.png" },

		{
			rel: "manifest",
			href: "/site.webmanifest",
			crossOrigin: "use-credentials",
		} as const, // necessary to make typescript happy

		// These should match the css preloads above to avoid css as render blocking resource
		{ rel: "icon", type: "image/svg+xml", href: "/favicons/favicon.svg" },
		{ rel: "stylesheet", href: tailwindStyleSheetUrl },
	].filter(Boolean)
}

export async function loader({ request }: LoaderFunctionArgs) {
	const timings = makeTimings("root loader")
	const userId = await time(() => getUserId(request), {
		timings,
		type: "getUserId",
		desc: "getUserId in root",
	})

	const user = userId
		? await time(
				() =>
					prisma.user.findUniqueOrThrow({
						select: {
							id: true,
							name: true,
							username: true,
							image: { select: { id: true } },
							roles: {
								select: {
									name: true,
									permissions: {
										select: { entity: true, action: true, access: true },
									},
								},
							},
							preferences: {
								select: { key: true, value: true },
							},
						},
						where: { id: userId },
					}),
				{ timings, type: "find user", desc: "find user in root" },
			)
		: null

	if (userId && !user) {
		console.info("something weird happened")
		// something weird happened... The user is authenticated but we can't find
		// them in the database. Maybe they were deleted? Let's log them out.
		await logout({ request, redirectTo: "/" })
	}

	const { toast, headers: toastHeaders } = await getToast(request)
	const honeyProps = honeypot.getInputProps()
	const locale = await i18next.getLocale(request)

	return json(
		{
			user,
			locale,
			requestInfo: {
				hints: getHints(request),
				origin: getDomainUrl(request),
				path: new URL(request.url).pathname,
				userPrefs: {
					theme: getTheme(request),
				},
			},
			ENV: getEnv(),
			toast,
			honeyProps,
			labels: user ? await getUserLabelNames({ userId: user.id }) : null,
		},
		{
			headers: combineHeaders({ "Server-Timing": timings.toString() }, toastHeaders),
		},
	)
}

export const handle = { i18n: ["translation"] }

export function headers({ loaderHeaders }: Parameters<HeadersFunction>[0]) {
	return {
		"Server-Timing": loaderHeaders.get("Server-Timing") ?? "",
	}
}

export const meta: MetaFunction = ({ matches, data, error }) => {
	const title = error || !data ? "Error" : getTitleFromMatches(matches)
	return [
		{ title: title ? `${title} | ${APP_NAME}` : APP_NAME },
		{
			name: "description",
			content: `A place to read, think, and share. Save articles to read later. Highlight and annotate text. Share your thoughts with the world.`,
		},
		{ name: "theme-color", content: "#ffffff", media: "(prefers-color-scheme: light)" },
		{ name: "theme-color", content: "#001D3D", media: "(prefers-color-scheme: dark)" },
	]
}

function Document({
	children,
	nonce,
	theme = "light",
	env = {},
	allowIndexing = true,
}: {
	children: React.ReactNode
	nonce: string
	theme?: Theme
	env?: Record<string, string>
	allowIndexing?: boolean
}) {
	const data = useLoaderData<typeof loader>()
	const locale = data?.locale ?? "en"
	const { i18n } = useTranslation()

	// This hook will change the i18n instance language to the current locale detected by the loader,
	// this way, when we do something to change the language, this locale will change and i18next
	// will load the correct translation files.
	useChangeLanguage(locale)

	return (
		<html
			className={`${theme} h-full overflow-hidden overscroll-none`}
			lang={locale}
			dir={i18n.dir()}
		>
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta
					name="viewport"
					content="width=device-width,initial-scale=1,maximum-scale=1.0,user-scalable=no"
				/>
				{allowIndexing ? null : <meta name="robots" content="noindex, nofollow" />}
				<Links />
			</head>
			<body className="h-screen w-screen overflow-hidden bg-background text-foreground">
				{children}
				<script
					nonce={nonce}
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(env)}`,
					}}
				/>
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
			</body>
		</html>
	)
}

function AppToaster({ theme }: { theme: Theme | null }) {
	return (
		<Toaster
			theme={theme ?? "system"}
			className="toaster group"
			closeButton
			position="top-center"
			toastOptions={{
				classNames: {
					toast: cn(
						"group toast group-[.toaster]:border-border group-[.toaster]:shadow-lg",
						"group-[.toaster]:bg-gray-800 group-[.toaster]:text-white group-[.toaster]:dark:bg-gray-100 group-[.toaster]:dark:text-gray-800",
					),
					description:
						"group-[.toast]:text-white group-[.toast]:dark:text-gray-800 text-lg",
					actionButton:
						"group-[.toast]:bg-primary group-[.toast]:text-primary-foreground",
					cancelButton: "group-[.toast]:bg-muted group-[.toast]:text-muted-foreground",
					closeButton:
						"bg-gray-800 dark:bg-gray-100 border-border group-[.toast]:text-white group-[.toast]:dark:text-gray-800",
				},
			}}
		/>
	)
}

function App() {
	const nonce = useNonce()
	const theme = useTheme()
	const data = useLoaderData<typeof loader>()
	const allowIndexing = data.ENV.ALLOW_INDEXING !== "false"
	useToast(data.toast)

	return (
		<Document nonce={nonce} theme={theme} allowIndexing={allowIndexing} env={data.ENV}>
			<AppLayout />
			<AppToaster theme={theme} />
			<EpicProgress />
		</Document>
	)
}

function AppWithProviders() {
	const data = useLoaderData<typeof loader>()
	return (
		<HoneypotProvider {...data.honeyProps}>
			<App />
		</HoneypotProvider>
	)
}

export default withSentry(AppWithProviders)

export function ErrorBoundary() {
	// the nonce doesn't rely on the loader so we can access that
	const nonce = useNonce()

	// NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
	// likely failed to run so we have to do the best we can.
	// We could probably do better than this (it's possible the loader did run).
	// This would require a change in Remix.

	// Just make sure your root route never errors out and you'll always be able
	// to give the user a better UX.

	return (
		<Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>
	)
}
