import * as Ariakit from "@ariakit/react"
import { Link, useLocation } from "@remix-run/react"
import * as React from "react"
import { cn } from "#app/utils/misc"
import { Icon, type IconName } from "./ui/icon"

interface MenubarProps extends Ariakit.MenubarProps {}

const Menubar = React.forwardRef<HTMLDivElement, MenubarProps>(function Menubar(props, ref) {
	return (
		<Ariakit.Menubar ref={ref} {...props}>
			<Ariakit.MenuProvider showTimeout={5000} hideTimeout={2500}>
				{props.children}
			</Ariakit.MenuProvider>
		</Ariakit.Menubar>
	)
})

interface MenuProps extends Ariakit.MenuItemProps {
	label: string
	placement?: Ariakit.MenuStoreProps["placement"]
	shift?: number
	href?: string
	isActive?: (pathname: string) => boolean
}

const Menu = React.forwardRef<HTMLDivElement, MenuProps>(function Menu(
	{
		shift = 0,
		placement = "bottom",
		label,
		href,
		children,
		className,
		isActive = href ? pathname => pathname === href : undefined,
		...props
	},
	ref,
) {
	const { pathname } = useLocation()

	const menuItem = (
		<Ariakit.MenuItem
			ref={ref}
			{...props}
			className={cn(
				"group flex gap-2 !whitespace-nowrap rounded-full px-4 py-2 transition-colors",
				"aria-expanded:bg-accent aria-expanded:text-accent-foreground",
				"hover:bg-accent hover:text-accent-foreground",
				href ? "cursor-pointer" : "cursor-default",
				isActive?.(pathname) ? "underline decoration-2 underline-offset-4" : undefined,
				className,
			)}
			render={href ? <Link to={href} /> : <button type="button" />}
		>
			{label}
			{children ? (
				<Icon
					name="chevron-down"
					size="sm"
					className="transition-all group-[[aria-expanded='true']]:rotate-180"
				/>
			) : null}
		</Ariakit.MenuItem>
	)

	// If there are no children, this means that this menu item is a leaf node in
	// the menubar, and we can render it as a simple menu item.
	if (!children) {
		return menuItem
	}

	return (
		<Ariakit.MenuProvider placement="bottom-start" showTimeout={1000}>
			<Ariakit.MenuButton showOnHover render={menuItem} />
			<Ariakit.Menu
				portal
				fitViewport
				unmountOnHide
				gutter={2}
				shift={-4}
				flip
				className={cn("menu min-w-44", className)}
			>
				{children}
			</Ariakit.Menu>
		</Ariakit.MenuProvider>
	)
})

interface MenuItemProps extends Ariakit.MenuItemProps {
	icon?: IconName
	label: string
	href?: string
}

const MenuItem = React.forwardRef<HTMLDivElement, MenuItemProps>(function MenuItem(
	{ label, href, icon, className, ...props },
	ref,
) {
	return (
		<Ariakit.MenuItem
			ref={ref}
			{...props}
			className={cn("menu-item", className)}
			render={href ? <Link to={href} /> : undefined}
		>
			{icon ? (
				<Icon name={icon} size="md">
					{label}
				</Icon>
			) : (
				label
			)}
		</Ariakit.MenuItem>
	)
})

export { Menubar as Container, Menu, MenuItem }
export type { MenubarProps as ContainerProps, MenuProps, MenuItemProps }
