import * as Ariakit from "@ariakit/react"
import { Link, Outlet, useFetcher, useMatches } from "@remix-run/react"
import { forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { BackAwareLink } from "#app/components/back-link"
import { AppCommandMenu } from "#app/components/command-menu.js"
import { ThemeSwitch, themeIcon, themeLabel } from "#app/routes/resources+/theme-switch"
import { cn, getTitleFromMatches, getUserImgSrc } from "#app/utils/misc"
import * as AppMenubar from "./components/app-menubar"
import { Icon } from "./components/ui/icon"
import { useRootRouteData } from "./utils/remix"
import { useOptionalUser } from "./utils/user"

function AppLogo({ className }: { className?: string }) {
	return (
		<svg
			width="289px"
			height="289px"
			viewBox="0 0 289 289"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			className={cn("h-10 w-10", className)}
		>
			<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<rect id="Rectangle" x="0" y="0" width="289" height="289" rx="32"></rect>
				<g id="Group" transform="translate(36, 23)" stroke="currentColor" strokeWidth="10">
					<path
						d="M131.508036,172.76946 C131.003057,160.892618 130.090758,151.36649 128.77114,144.191077 C127.451521,137.015664 124.810494,127.220612 120.848058,114.80592 C116.389717,112.189109 113.078582,111.107606 110.914654,111.561412 C108.750725,112.015218 105.938271,114.380921 102.477292,118.658522 L120.848058,208.420124 C125.25933,211.647182 128.812656,213.260711 131.508036,213.260711 C134.203416,213.260711 139.447597,211.647182 147.240579,208.420124 L153.94064,199.125554 C153.843596,176.895488 152.813826,159.996435 150.851333,148.428395 C148.888839,136.860356 144.074745,119.312403 136.409052,95.7845365 C122.394707,86.6817482 112.635573,82.4658113 107.131648,83.1367257 C101.627724,83.8076401 94.7141098,88.023577 86.3908064,95.7845365 L81.1492932,107.879291 L102.477292,239.759702 C125.291659,242.693262 149.205317,243.634928 174.218265,242.584699 C199.231214,241.534471 213.020013,238.586626 215.584663,233.741165 C216.138446,213.66677 216.138446,197.948092 215.584663,186.585133 C214.066061,155.425247 212.174177,130.171434 209.198125,111.561412 C206.672102,95.7655409 207.260274,93.8713534 200.926176,69.8703622 C197.45252,56.7080779 190.314589,34.6547275 179.512383,3.71031088 C165.474432,1.23677029 154.015289,0 145.134953,0 C136.254617,0 114.030244,1.23677029 78.461833,3.71031088 L30.8699657,13.3512127 L33.974376,22.8033206 L46.9886241,62.4282625 C50.725103,71.2427534 54.281449,86.3930962 57.6576622,107.879291 C61.0338755,129.365485 64.2493852,162.53008 67.3041914,207.373076 L20.8115672,99.6586165 L0,62.4282625 L24.4300093,53.1524853 L36.8176203,92.3104827 L67.3041914,207.373076"
						id="Path"
					></path>
					<polyline id="Path-2" points="24.9826215 54 18 36.7115096 36 29"></polyline>
				</g>
			</g>
		</svg>
	)
}

function AppName({ className }: { className?: string }) {
	return (
		<div className={className}>
			<span className="font-semibold">klip</span>
			<span className="font-light">stack</span>
		</div>
	)
}

const MenuList = forwardRef<HTMLDivElement, Ariakit.MenuProps>(function Menu(props, ref) {
	const menu = Ariakit.useMenuContext()
	return (
		<Ariakit.Menu
			ref={ref}
			portal
			fitViewport
			unmountOnHide
			overlap={!!menu?.parent}
			gutter={2}
			shift={-4}
			flip={menu?.parent ? true : "bottom-end"}
			{...props}
			className={cn("menu min-w-44", props.className)}
		/>
	)
})

function AddArticleButton() {
	return (
		<Ariakit.TooltipProvider>
			<Ariakit.TooltipAnchor
				className="btn btn-primary btn-icon flex-shrink-0 rounded-full"
				render={<BackAwareLink to="/add" />}
			>
				<Icon name="plus" size="lg">
					<span className="sr-only">Add</span>
				</Icon>
			</Ariakit.TooltipAnchor>
			<Ariakit.Tooltip className="z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2">
				Add article
			</Ariakit.Tooltip>
		</Ariakit.TooltipProvider>
	)
}

type User = NonNullable<ReturnType<typeof useOptionalUser>>

function UserDropdown({ user }: { user: User | null }) {
	const { t } = useTranslation()
	const fetcher = useFetcher()

	if (!user) {
		return (
			<>
				<Link
					to="/login"
					className="flex flex-shrink-0 gap-2 !whitespace-nowrap rounded-full p-2 transition-colors hover:bg-accent hover:text-accent-foreground"
					aria-label="Log in"
				>
					<Icon name="avatar" size="lg" />
				</Link>
				<Link
					to="/signup"
					className="btn btn-primary ml-2 flex-shrink-0 !whitespace-nowrap rounded-full px-4 py-2 transition-colors"
					prefetch="intent"
				>
					Get started
				</Link>
			</>
		)
	}

	return (
		<Ariakit.MenuProvider>
			<Ariakit.TooltipProvider>
				<Ariakit.TooltipAnchor
					className="flex flex-shrink-0 rounded-full transition-colors hover:bg-accent hover:text-accent-foreground"
					render={<Ariakit.MenuButton aria-label={user.name ?? user.username} />}
				>
					{user.image ? (
						<img
							src={getUserImgSrc(user.image?.id)}
							alt=""
							className="h-10 w-10 rounded-full object-cover"
							aria-hidden="true"
						/>
					) : (
						<Icon name="avatar" size="xl" className="h-10 w-10" />
					)}
				</Ariakit.TooltipAnchor>
				<Ariakit.Tooltip className="z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2">
					Account options menu
				</Ariakit.Tooltip>
			</Ariakit.TooltipProvider>

			<MenuList>
				<Ariakit.MenuItem
					className="menu-item"
					render={<Link prefetch="intent" to={`/users/${user.username}`} />}
				>
					<Icon name="avatar" size="lg">
						{t("profile.link")}
					</Icon>
				</Ariakit.MenuItem>

				<ThemeSwitch>
					{({ mode, onClick }) => (
						<Ariakit.MenuItem
							render={<button type="button" />}
							onClick={event => {
								event.preventDefault()
								onClick()
							}}
							className="menu-item w-full"
						>
							<Icon name={themeIcon[mode]} size="lg">
								Theme
							</Icon>
						</Ariakit.MenuItem>
					)}
				</ThemeSwitch>

				<Ariakit.MenuSeparator />

				<Ariakit.MenuItem
					className="menu-item"
					onClick={() => fetcher.submit(null, { method: "POST", action: "/logout" })}
				>
					<Icon name="exit" size="lg">
						{t("logout")}
					</Icon>
				</Ariakit.MenuItem>
			</MenuList>
		</Ariakit.MenuProvider>
	)
}

const ARTICLE_PATHS = ["/unread", "/finished", "/favorites", "/archived"]

function AppNav({ user }: { user: User | null }) {
	const matches = useMatches()
	const title = getTitleFromMatches(matches)
	const { labels } = useRootRouteData()

	const articleRouteId = "routes/_article+/article.$articleId"
	const isArticleRoute = matches.some(match => match.id === articleRouteId)
	if (isArticleRoute) return null

	return (
		<div className="sticky top-0 z-10 flex justify-center border-b border-border bg-background px-4 md:px-8">
			<div
				className={cn(
					"flex w-full max-w-7xl items-center justify-between gap-4 py-3",
					user ? null : "justify-between",
				)}
			>
				<div className="flex flex-1 items-center gap-1">
					<Link
						to="/"
						className="flex items-center gap-1 text-h1 leading-snug text-foreground"
					>
						<AppLogo />
						<AppName className="hidden md:block" />
					</Link>
					{title ? (
						<span aria-hidden className="px-1 text-2xl">
							/
						</span>
					) : null}
					{title ? <h1 className="line-clamp-1 text-h1 font-medium">{title}</h1> : null}
				</div>

				<AppMenubar.Container className="hidden items-center gap-1 xl:flex">
					{user ? (
						<AppMenubar.Menu
							label="Articles"
							href="/unread"
							isActive={pathname => ARTICLE_PATHS.includes(pathname)}
						>
							<AppMenubar.MenuItem href="/unread" label="Unread" icon="file-text" />
							<AppMenubar.MenuItem href="/finished" label="Finished" icon="check" />
							<AppMenubar.MenuItem
								href="/favorites"
								label="Favorites"
								icon="star-outline"
							/>
							<AppMenubar.MenuItem href="/archived" label="Archived" icon="archive" />
						</AppMenubar.Menu>
					) : null}

					{user && labels?.length ? (
						<AppMenubar.Menu
							label="Labels"
							href="/labels"
							isActive={pathname =>
								pathname.startsWith("/label/") || pathname.startsWith("/labels")
							}
						>
							{labels.map(label => (
								<AppMenubar.MenuItem
									key={label}
									href={`/label/${label}`}
									label={label}
									icon="hashtag"
								/>
							))}
						</AppMenubar.Menu>
					) : null}

					{user ? <AppMenubar.Menu href="/highlights" label="Highlights" /> : null}
					{!user ? <AppMenubar.Menu href="/about" label="About" /> : null}

					<AppMenubar.Menu href="/discover" label="Discover" />
				</AppMenubar.Container>

				<div className="flex items-center justify-end gap-2">
					{user ? <AppCommandMenu /> : null}

					{user ? (
						<AddArticleButton />
					) : (
						<ThemeSwitch>
							{({ mode, onClick }) => (
								<button
									type="button"
									onClick={onClick}
									className="flex gap-2 !whitespace-nowrap rounded-full p-2 transition-colors hover:bg-accent hover:text-accent-foreground"
								>
									<Icon name={themeIcon[mode]} size="lg">
										<span className="sr-only">{themeLabel[mode]}</span>
									</Icon>
								</button>
							)}
						</ThemeSwitch>
					)}
					<UserDropdown user={user} />
				</div>
			</div>
		</div>
	)
}

function AppLayout() {
	const user = useOptionalUser()
	return (
		<div className="flex h-full flex-col overflow-hidden">
			<AppNav user={user ?? null} />
			<div className="flex flex-1 overflow-hidden">
				<Outlet />
			</div>
		</div>
	)
}

export { AppLayout }
